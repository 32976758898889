<template>
  <div class="page-wrapper">
    <div class="filter white-block">
      <div class="filter-item border-right-splite">
        <span class="label">
          <pre>日期范围 :  </pre>
        </span>
        <span class="input">
          <DatePicker
            type="daterange"
            size="small"
            style="width: 230px"
            :start-date="filterOptions.startDate"
            :value="filterOptions.dateRange"
            format="yyyy-MM-dd"
            :editable="false"
            placement="bottom-end"
            @on-change="dateChangeHandler"
          ></DatePicker>
        </span>
      </div>
      <div class="filter-item border-right-splite">
        <Button
          class="btn"
          v-for="(period, index) in periodList"
          :key="period.text"
          :type="period.type"
          @click.native="periodHandler(index)"
          >{{ period.text }}</Button
        >
      </div>
      <Button class="export-btn" type="success" @click="exportParams"
        >导出</Button
      >
    </div>
    <div class="content">
      <div class="left">
        <div class="statistics white-block border-radius-10">
          <div class="statistics-item">
            <div class="sta-title">飞行次数</div>
            <div class="sta-data border-right-splite">
              {{ formatCount(pageData.countres.count) }}
            </div>
          </div>
          <div class="statistics-item">
            <div class="sta-title">飞行时间</div>
            <div class="sta-data border-right-splite">
              {{ formatSeconds(pageData.countres.alltime) }}
            </div>
          </div>
          <div class="statistics-item">
            <div class="sta-title">飞行距离</div>
            <div class="sta-data">
              {{ formatMiles(pageData.countres.distance) }}
            </div>
          </div>
        </div>
        <div class="time-rank rank white-block border-radius-10">
          <div class="rank-title">
            <!-- {{ isSuperAdmin ? "总飞行时长" : "飞手飞行时长" }} -->
            飞行时长
          </div>
          <Tabs value="name1">
            <TabPane label="飞手" name="name1">
              <div
                class="rank-item border-bottom-splite"
                v-for="(rank, index) in pageData.flyorder"
                :key="index"
              >
                <img class="img" :src="medals[index]" alt v-if="index < 3" />
                <span class="img" v-else>{{ index + 1 }}</span>
                <span class="center">{{ rank.name }}</span>
                <span style="color: #fff">{{ formatSeconds(rank.flytime) }}</span>
              </div>
              <div class="no-data" v-if="!pageData.flyorder.length">
                暂无数据
              </div>
            </TabPane>
            <TabPane label="单位" name="name2">
              <div
                class="rank-item border-bottom-splite"
                v-for="(rank, index) in pageData.team_count"
                :key="index"
              >
                <img class="img" :src="medals[index]" alt v-if="index < 3" />
                <span class="img" v-else>{{ index + 1 }}</span>
                <span class="center">{{ rank.team_name }}</span>
                <span>{{ formatSeconds(rank.duration_sum) }}</span>
              </div>
              <div class="no-data" v-if="!pageData.team_count.length">
                暂无数据
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
      <div class="right">
        <div class="chart white-block border-radius-10">
          <div class="title">
            <span>每日飞行数据</span>
            <span class="desc">(蓝线每日数据，紫线为平均值)</span>
          </div>
          <div class="lines" id="chart"></div>
        </div>
        <div class="r-bottom">
          <div class="equ-rank rank white-block border-radius-10">
            <div class="rank-title border-bottom-splite">设备里程排行</div>
            <div
              class="rank-item border-bottom-splite"
              v-for="(rank, index) in pageData.distanceres"
              :key="index"
            >
              <span class="center">{{ rank.DRONEMODEL }}</span>
              <span>{{ formatMiles(rank.distance) }}</span>
            </div>
            <div class="no-data" v-if="!pageData.distanceres.length">
              暂无数据
            </div>
          </div>
          <div class="equ-rank rank white-block border-radius-10">
            <div class="rank-title border-bottom-splite">设备次数排行</div>
            <div
              class="rank-item border-bottom-splite"
              v-for="(rank, index) in pageData.flycount"
              :key="index"
            >
              <span class="center">{{ rank.DRONEMODEL }}</span>
              <span>{{ formatCount(rank.count) }}</span>
            </div>
            <div class="no-data" v-if="!pageData.flycount.length">暂无数据</div>
          </div>
        </div>
      </div>
      <Spin size="large" fix v-if="spinShow"></Spin>
    </div>
  </div>
</template>

<script>
import GoldMedal from '@/assets/img/statistics/gold-medal.png'
import SilverMedal from '@/assets/img/statistics/silver-medal.png'
import BronzeMedal from '@/assets/img/statistics/bronze-medal.png'
import _ from 'lodash'
import Api from '@/utils/api.js'
export default {
  name: 'statistics',
  created() {
    this.user_info = JSON.parse(
      window.sessionStorage.getItem('user_info') || '{}'
    )
  },
  mounted() {
    if (sessionStorage.getItem('team_id') === null) {
      this.fetchData()
    } else {
      this.filterOptions.team = Number(sessionStorage.getItem('team_id'))
      this.fetchData()
    }
    this.$_bus.$off('change_Team').$on('change_Team', (value) => {
      console.log('00', value)
      this.filterOptions.team = value
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },

  data() {
    return {
      filterUpdateLock: true, // 页面初始化的时候需要锁住不让相关变量被赋值的时候产生更新导致页面重新请求数据 等页面加载完成之后就可以了
      spinShow: true,
      filterOptions: {
        startDate: this.getLastMonthDate(),
        dateRange: ['', ''],
        // second_id: -1,
        // pm_first_id: -1,
        team: -1,
        checkdate: 'theweek',
      },
      filterConfigs: {
        team: [],
        project: [],
        company: [],
      },
      pageData: {
        countres: {
          count: 0,
          alltime: 0,
          distance: 0,
        },
        dis_every: [],
        flyorder: [],
        distanceres: [],
        flycount: [],
        team_count: [],
      },
      periodList: [
        {
          text: '本周',
          type: 'primary',
          value: 'theweek',
        },
        {
          text: '上周',
          type: 'default',
          value: 'lastweek',
        },
        {
          text: '本月',
          type: 'default',
          value: 'themonth',
        },
        {
          text: '上月',
          type: 'default',
          value: 'lastmonth',
        },
      ],
      medals: [GoldMedal, SilverMedal, BronzeMedal],
    }
  },
  computed: {
    // 超管
    // isSuperAdmin() {
    //   return (
    //     JSON.parse(window.sessionStorage.getItem('user_info') || '{}').type ===
    //     0
    //   );
    // },
    // first_id: {
    //   get() {
    //     return { first_id: 36 };
    //   },
    // },
    // 获取数据的参数
    fetchParams: {
      get() {
        return {
          startdate: this.filterOptions.dateRange[0] || '',
          enddate:
            this.filterOptions.dateRange[1] ||
            this.$moment().format('YYYY-MM-DD'),
          checkdate: this.filterOptions.checkdate,
          team_id: this.filterOptions.team,
          //   ...(this.filterOptions.team && parseInt(this.filterOptions.team) >= 0
          //     ? { team_id: this.filterOptions.team }
          //     : { team_id: -1 }),
        }
      },
    },
  },
  watch: {
    // 修改参数之后重新获取
    filterOptions: {
      deep: true,
      handler: function () {
        console.log(this.filterOptions.team)
        if (this.filterUpdateLock) return
        this.fetchData()
      },
    },
  },
  methods: {
    // 当前日期的前一个月
    getLastMonthDate() {
      return new Date(new Date().getTime() - 30 * 24 * 3600 * 1000)
    },
    // 日期改变之后重新获取数据
    dateChangeHandler(e) {
      this.filterOptions.dateRange = e
      this.fetchData()
    },
    // 格式化日期
    formatSeconds(seconds) {
      let hour = parseInt(seconds / 3600)
      let minute = parseInt((seconds - 3600 * hour) / 60)
      seconds = seconds - 3600 * hour - 60 * minute
      return (
        (hour ? hour + 'h' : '') + (minute ? minute + 'm' : '') + seconds + 's'
      )
    },
    // 格式化里程
    formatMiles(miles) {
      let km = parseFloat(miles / 1000).toFixed(1)
      return km >= 1 ? km + ' km' : miles + ' m'
    },
    formatCount(count) {
      return count + ' 次'
    },
    // chart数据
    initChart() {
      this.myChart = this.$echarts.init(document.getElementById('chart'))
      const option = {
        grid: {
          top: '10%',
          bottom: '10%',
          left: '4%',
          right: '4%',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          },
        },
        xAxis: {
          name: '日期',
          nameTextStyle:{
            color:'#fff',
            fontSize:12,
          },
          nameLocation: 'end',
          boundaryGap: false,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            alignWithLabel: true,
          },
          splitLine: {
            show: true,
            interval: 0,
            lineStyle: {
              color: '#F8F8F8',
            },
          },
          axisLabel: {
            show: true,                                 //是否显示
            inside: false,                             //坐标轴刻度文字指向        (true表示向上   false表示向下)
            margin: 10,                                 //坐标轴刻度文字与轴线之间的距离
            color: 'white',                              //坐标轴刻度文字的颜色
            fontSize: 14,                                //坐标轴刻度文字的大小         (用数字表示)
            fontWeight:'lighter',                        //坐标轴刻度文字的加粗程度    (可选bold   bolder  lighter  normal)
            fontstyle:'normal',                        //坐标轴刻度文字的样式          (可选normal  italic   oblique)
            fontFamily:'华文行楷',                        //坐标轴刻度文字的风格        (可选楷体  宋体  华文行楷等等)
            padding: [5, 0, 2, -5],
            formatter: function (value) {//在这里写你需要的时间格式
              var t_date = new Date(value);
              return [t_date.getMonth() + 1, t_date.getDate()].join('-')
              // + " " + [t_date.getHours(), t_date.getMinutes()].join(':'); 时分
            }
          },
          data: [],
        },
        yAxis: {
          axisLabel: {
            show: true,                                 //是否显示
            inside: false,                             //坐标轴刻度文字指向        (true表示向上   false表示向下)
            margin: 10,                                 //坐标轴刻度文字与轴线之间的距离
            color: 'white',                              //坐标轴刻度文字的颜色
            fontSize: 14,                                //坐标轴刻度文字的大小         (用数字表示)
            fontWeight:'lighter',                        //坐标轴刻度文字的加粗程度    (可选bold   bolder  lighter  normal)
            fontstyle:'normal',                        //坐标轴刻度文字的样式          (可选normal  italic   oblique)
            fontFamily:'华文行楷',                        //坐标轴刻度文字的风格        (可选楷体  宋体  华文行楷等等)
            padding: [5, 0, 2, -5],
          },
          name: '距离',
          nameTextStyle:{
            color:'#fff',
            fontSize:12,
          },
          nameLocation: 'end',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#F8F8F8',
            },
          },
        },
        series: [
          {
            name: '',
            type: 'line',
            smooth: true,
            showSymbol: false,
            clip: true,
            lineStyle: {
              color: '#B2E7FF',
              width: 4,
              smooth: true,
              smoothMonotone: 'x',
            },
            itemStyle: {
              color: '#B2E7FF',
              borderColor: '#67C7F2',
              borderWidth: 1,
            },
            markLine: {
              silent: true,
              label: {
                show: false,
              },
              lineStyle: {
                color: '#C179E5',
                width: 3,
                type: 'solid',
              },
              data: [
                
                  {
                    type: 'average',
                    name: '平均值',
                  },
              ],
            },
            data: [],
          },
        ],
      }
      option.xAxis.data = this.pageData.dis_every.map((ele) => {
        return ele.date || ''
      })
      option.series[0].data = this.pageData.dis_every.map((ele) => {
        return parseInt(ele.distance) || 0
      })
      // option.xAxis.data = ['2020-02-02', '2020-02-03','2020-02-02', '2020-02-03','2020-02-02', '2020-02-03','2020-02-02', '2020-02-03','2020-02-02', '2020-02-03','2020-02-02', '2020-02-03','2020-02-02', '2020-02-03','2020-02-02', '2020-02-03','2020-02-02', '2020-02-03','2020-02-02', '2020-02-03','2020-02-02', '2020-02-03','2020-02-02', '2020-02-03']
      // option.series[0].data = [100, 50,100, 50,100, 50,100, 50,100, 50,100, 50,100, 50,100, 50,100, 50,100, 50,100, 50,100, 50]
      this.myChart.setOption(option)
      let _this = this
      this.resize = _.debounce(function () {
        _this.myChart.resize()
      }, 1000)
      window.addEventListener('resize', this.resize)
    },
    // 获取配置并做映射 添加全部选项
    fetchConfig() {
      this.$post(Api.getRrackAllCountConfig())
        .then((res) => {
          if (res.data) {
            console.log('工程数据', res.data)
            // if (!this.isSuperAdmin) {
            this.filterConfigs.project =
              (res.data.secondpm &&
                res.data.secondpm.map((item) => {
                  return {
                    label: item.pm_name,
                    value: item.pm_id,
                  }
                })) ||
              []
            if (this.filterConfigs.project.length) {
              this.filterConfigs.project.unshift({
                label: '全部',
                value: -1,
              })
            }
            // } else {
            //   this.filterConfigs.company =
            //     (res.data.firstpm &&
            //       res.data.firstpm.map((item) => {
            //         return {
            //           label: item.pm_name,
            //           value: item.pm_id,
            //         };
            //       })) ||
            //     [];
            //   if (this.filterConfigs.company.length) {
            //     this.filterConfigs.company.unshift({
            //       label: '全部',
            //       value: -1,
            //     });
            //   }
            // }
            this.filterConfigs.team =
              (res.data.teamlist &&
                res.data.teamlist.map((item) => {
                  return {
                    label: item.team_name,
                    value: item.id,
                  }
                })) ||
              []
            if (this.filterConfigs.team.length) {
              this.filterConfigs.team.unshift({
                label: '全部',
                value: -1,
              })
            }
            this.fetchData()
          } else {
            this.$notice.error({
              title: '配置接口异常,返回无数据',
            })
          }
          this.filterUpdateLock = false
        })
        .catch((err) => {
          this.spinShow = false
          this.filterUpdateLock = false
          this.$notice.error({
            title: '配置接口异常,返回无数据',
            desc: err.toString(),
          })
        })
    },
    // 获取数据
    fetchData() {
      this.$post(Api.getRrackAllCount(), _.merge({}, this.fetchParams))
        .then((res) => {
          console.log(res)
          if (res.data) {
            this.pageData = res.data
          } else {
            this.$notice.error({
              title: '统计列表接口异常,返回无数据',
            })
          }
          this.initChart()
        })
        .catch((err) => {
          console.log(err)
          this.$notice.error({
            title: '统计列表接口异常,返回无数据',
            desc: err.toString(),
          })
        })
        .finally(() => {
          this.filterUpdateLock = false
          this.spinShow = false
        })
    },
    // 切换日期种类
    periodHandler(index) {
      this.filterOptions.checkdate = this.periodList[index].value
      this.periodList = this.periodList.map((ele, i) => {
        return {
          text: ele.text,
          type: parseInt(index) === i ? 'primary' : 'default',
          value: ele.value,
        }
      })
    },
    exportParams() {
      let path = this.pageData.export_path
      if (path !== '') {
        window.location.href =
          'https://www.hnmusk.cn' + this.pageData.export_path
      } else {
        this.$Message.error('暂无数据')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page-wrapper {
  width: 100%;
  height: 100%;
  background-color: #3c3d43;
  padding: $space10 $space10;
  display: flex;
  flex-direction: column;
  /*font-size: pcRem(18);*/
  .filter {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: $space10;
    flex-wrap: wrap;
    margin-bottom: $space10;
    flex-wrap: nowrap;
    .filter-item {
      display: flex;
      align-items: center;
      padding: 0 $space45;
      margin-bottom: $space10;
      line-height: 1;
      &:last-child {
        border-right: none;
      }
      .label {
        white-space: pre;
      }
      .btn {
        border: 1px solid rgba(220, 222, 226, 1);
        margin-right: $space20;
        &:last-child {
          margin-right: 0;
        }
      }
      .ivu-select-item {
        padding: pcRem(7) pcRem(16);
      }
    }
    .export-btn {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
  .content {
    position: relative;
    flex: 1;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    .left {
      flex: 0 0 30%;
      margin-right: $space10;
      display: flex;
      flex-direction: column;

      .statistics {
        display: flex;
        align-items: center;
        padding: $space30 0;
        margin-bottom: $space10;
        .statistics-item {
          flex: 1;
          text-align: center;
          white-space: nowrap;
          .sta-title {
            @include sc(24px);
            font-weight: bold;
            color: #fff;
          }
          .sta-data {
            padding: $space40 $space30;
            @include sc(18px, rgba(64, 158, 255, 1));
            font-weight: bold;
          }
        }
      }
      .time-rank {
        font-weight: bold;
        flex: 1;
        color: #fff;
        /deep/ .ivu-tabs-nav .ivu-tabs-tab {
          color: #fff;
        }
        /deep/ .ivu-tabs-nav .ivu-tabs-tab-active {
          color: #2d8cf0;
        }
      }
    }
    .rank {
      white-space: nowrap;
      padding: 0 $space30;
      overflow-y: auto;
      color: #fff;
      .rank-title {
        font-size: pcRem(24);
        color: #fff;
        font-weight: bold;
        padding: $space30 0;
      }
      .rank-item {
        padding: $space10 $space20;
        display: flex;
        align-items: center;
        .img {
          @include wh(43px, 53px);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .center {
          color: #fff;
          flex: 1;
          padding-left: $space40;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      .chart {
        padding: 0 $space20 $space20;
        margin-bottom: $space10;
        .title {
          @include sc(24px);
          font-weight: bold;
          padding: $space20 $space20;
          color: #fff;
          .desc {
            @include sc(18px, #999);
            font-weight: normal;
            margin-left: $space40;
          }
        }
        .lines {
          height: 400px;
        }
      }
      .r-bottom {
        overflow: hidden;
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: nowrap;
        .equ-rank {
          flex: 1;
          &:first-child {
            margin-right: $space10;
          }
          .rank-title {
            padding: $space20 0 $space10 0;
          }
        }
      }
    }
  }
}
.white-block {
  background-color: #313131;
}
</style>
